// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-get-quote-address-jsx": () => import("./../../../src/pages/get-quote/address.jsx" /* webpackChunkName: "component---src-pages-get-quote-address-jsx" */),
  "component---src-pages-get-quote-contact-info-jsx": () => import("./../../../src/pages/get-quote/contact-info.jsx" /* webpackChunkName: "component---src-pages-get-quote-contact-info-jsx" */),
  "component---src-pages-get-quote-courses-jsx": () => import("./../../../src/pages/get-quote/courses.jsx" /* webpackChunkName: "component---src-pages-get-quote-courses-jsx" */),
  "component---src-pages-get-quote-date-jsx": () => import("./../../../src/pages/get-quote/date.jsx" /* webpackChunkName: "component---src-pages-get-quote-date-jsx" */),
  "component---src-pages-get-quote-dietary-restrictions-jsx": () => import("./../../../src/pages/get-quote/dietary-restrictions.jsx" /* webpackChunkName: "component---src-pages-get-quote-dietary-restrictions-jsx" */),
  "component---src-pages-get-quote-dietary-restrictions-specifics-jsx": () => import("./../../../src/pages/get-quote/dietary-restrictions-specifics.jsx" /* webpackChunkName: "component---src-pages-get-quote-dietary-restrictions-specifics-jsx" */),
  "component---src-pages-get-quote-error-jsx": () => import("./../../../src/pages/get-quote/error.jsx" /* webpackChunkName: "component---src-pages-get-quote-error-jsx" */),
  "component---src-pages-get-quote-hob-count-jsx": () => import("./../../../src/pages/get-quote/hob-count.jsx" /* webpackChunkName: "component---src-pages-get-quote-hob-count-jsx" */),
  "component---src-pages-get-quote-hob-jsx": () => import("./../../../src/pages/get-quote/hob.jsx" /* webpackChunkName: "component---src-pages-get-quote-hob-jsx" */),
  "component---src-pages-get-quote-meal-jsx": () => import("./../../../src/pages/get-quote/meal.jsx" /* webpackChunkName: "component---src-pages-get-quote-meal-jsx" */),
  "component---src-pages-get-quote-oven-jsx": () => import("./../../../src/pages/get-quote/oven.jsx" /* webpackChunkName: "component---src-pages-get-quote-oven-jsx" */),
  "component---src-pages-get-quote-people-jsx": () => import("./../../../src/pages/get-quote/people.jsx" /* webpackChunkName: "component---src-pages-get-quote-people-jsx" */),
  "component---src-pages-get-quote-thanks-jsx": () => import("./../../../src/pages/get-quote/thanks.jsx" /* webpackChunkName: "component---src-pages-get-quote-thanks-jsx" */),
  "component---src-pages-imprint-jsx": () => import("./../../../src/pages/imprint.jsx" /* webpackChunkName: "component---src-pages-imprint-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-tag-jsx": () => import("./../../../src/templates/tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */)
}


import React from 'react';
import { addDays } from 'date-fns';

const defaultState = {
  address: '',                              // streetname, housenumber, zip code
  peopleCount: '',                          // 2, 3-6, 7-12
  meal: '',                                 // lunch, dinner
  hob: '',                                  // electric, gas, induction
  hobCount: 0,                              // 2, 3, 4, 5
  oven: '',                                 // yes, no
  date: addDays(new Date(), 4),      // 2020-01-04 18:00:00
  courses: 0,                               // 4, 7
  dietaryRestrictions: '',                  // yes, no
  vegetarian: false,                        // yes, no
  vegan: false,                             // yes, no
  noGluten: false,                          // yes, no
  noNuts: false,                            // yes, no
  noShellfish: false,                       // yes, no
  noDairy: false,                           // yes, no
  otherRestrictions: '',                    // [free text]
  name: '',                                 // john doe
  email: '',                                // john.doe.@email.com
  phone: '',                                // 12345/12345678
  notes: '',                                // [free text]
  set: () => {},
  reset: () => {}
}

const QuoteContext = React.createContext(defaultState);

class QuoteContextProvider extends React.Component {
  state = {
    address: '',                              // streetname, housenumber, zip code
    peopleCount: '',                          // 2, 3-6, 7-12
    meal: '',                                 // lunch, dinner
    hob: '',                                  // electric, gas, induction
    hobCount: 0,                              // 2, 3, 4, 5
    oven: '',                                 // yes, no
    date: addDays(new Date(), 4),      // 2020-01-04 18:00:00
    courses: 0,                               // 4, 7
    dietaryRestrictions: '',                  // yes, no
    vegetarian: false,                        // yes, no
    vegan: false,                             // yes, no
    noGluten: false,                          // yes, no
    noNuts: false,                            // yes, no
    noShellfish: false,                       // yes, no
    noDairy: false,                           // yes, no
    otherRestrictions: '',                    // [free text]
    name: '',                                 // john doe
    email: '',                                // john.doe.@email.com
    phone: '',                                // 12345/12345678
    notes: '',                                // [free text]
    set: () => {},
    reset: () => {}
  }

  set = (partialState) => {
    this.setState(partialState);
    console.log('setting partial state: ', partialState);
  }

  reset = () => {
    this.setState({
      address: '',                              // streetname, housenumber, zip code
      peopleCount: '',                          // 2, 3-6, 7-12
      meal: '',                                 // lunch, dinner
      hob: '',                                  // electric, gas, induction
      hobCount: 0,                              // 2, 3, 4, 5
      oven: '',                                 // yes, no
      date: addDays(new Date(), 4),      // 2020-01-04 18:00:00
      courses: 0,                               // 4, 7
      dietaryRestrictions: '',                  // yes, no
      vegetarian: false,                        // yes, no
      vegan: false,                             // yes, no
      noGluten: false,                          // yes, no
      noNuts: false,                            // yes, no
      noShellfish: false,                       // yes, no
      noDairy: false,                           // yes, no
      otherRestrictions: '',                    // [free text]
      name: '',                                 // john doe
      email: '',                                // john.doe.@email.com
      phone: '',                                // 12345/12345678
      notes: ''                                 // [free text]
    });
  }

  render() {
    return(
      <QuoteContext.Provider
        value={{
          state: this.state,
          address: this.state.address,
          peopleCount: this.state.peopleCount,
          meal: this.state.meal,
          hob: this.state.hob,
          hobCount: this.state.hobCount,
          oven: this.state.oven,
          date: this.state.date,
          courses: this.state.courses,
          dietaryRestrictions: this.state.dietaryRestrictions,
          vegetarian: this.state.vegetarian,
          vegan: this.state.vegan,
          noGluten: this.state.noGluten,
          noNuts: this.state.noNuts,
          noShellfish: this.state.noShellfish,
          noDairy: this.state.noDairy,
          otherRestrictions: this.state.otherRestrictions,
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          notes: this.state.notes,
          set: (partialState) => this.set(partialState),
          reset: () => this.reset()
        }}
      >
        {this.props.children}
      </QuoteContext.Provider>
    );
  }
}

export default QuoteContext;

export { QuoteContextProvider };
